body {
  overflow: hidden;
}

.blank {
  background-color: white;
}

.noborder {
  border: none;
}

.main-area {
  width: 95vw;
  height: 84vh;
}

.table-body{
  position: relative;
  top: 0;
  left: 0;
  height: 95vw;
  height: 80vh;
  /* overflow-x: scroll; */
  overflow-y: scroll;
}

span {
  letter-spacing: 0.2px;
}

.styling-menu {
  padding: 0.3%;
}

#user_area, #text-color-palette,#fill-background-palette, #kesalahan_komplain_area {
  padding: 2vw;
  position: absolute;
  background-color: white;
  display: none;
  width: 24vw;
}

#user_area {
  z-index: 88;
  width: 15vw;
  padding: 1vw;
}

#nav_pages {
  border: none;
  z-index: 80;
  height: 4vh;
  width: 93vw;
  bottom: 1%;
  left: 50%;
  transform: translateX(-50%);
}

.emp_bubble {
  transform: translateY(-20%);
}

.nav_marketplace {
  background-color: rgb(70, 181, 87);
  color: white;
}

.dot {
  height: 2vw;
  width: 2vw;
  padding: 2%;
  margin : 1px;
  border-radius: 50%;
  border: 1px solid black;
  display: inline-block;
}



#_cont_left_menu {
  position: absolute;
  border: none;
  width: 3vw;
  height: 95vh;
}

#_cont_right_menu {
  position: absolute;
  border: none;
  transform: translate(97vw,0);
  width: 3vw;
  height: 95vh;
}

.bubble {
  padding: 2%;
  margin: 10%;
}

.left_menu {
  border: none;
  border-radius: 50%;
  background-color: black;
  width: 2.5vw;
  height: 2.5vw;
  margin: 0.5vw;
  margin-bottom: 4vh;
}

#magic-point {
  z-index: 2;
  position: absolute;
  width: 10px;
  height: 10px;
  display: none;
}

.form-control:disabled {
  background: #ffffff;
}

.admin {
  color: white;
  background-color: rgb(26, 123, 201);
}

.admin-inv {
  background-color: white;
  color: rgb(26, 123, 201);
}

.y-a {
  background-color: rgb(203, 231, 255);
}

.y-b {
  background-color: rgb(167, 210, 248);
}

.packing {
  color: white;
  background-color: rgb(204, 36, 36);
}

.cgo {  
  color: white;
  background-color: rgb(70, 181, 87);
}

.op-mesin {
  color: white;
  background-color: rgb(236, 95, 48);
  
}

.z-a {
  object-fit: cover;
  background-color: rgb(236, 95, 48);
  width: 100%;
}

.op-cutting {
  color: white;
  background-color : rgb(223, 67, 189);
}

.se {
  color: white;
  background-color : rgb(0, 0, 0);
}

/* parent.childNodes[1].style.color = "rgb(155, 102, 102)";  */

/* .main-table {
  overflow-y: scroll;
} */

.guide {
  color: rgb(128, 128, 128);
}



[class*="col"] {
  border: none;
}
[class*="row"] {
  border: none;
}

div[class*="menu-ohp"] {
  padding: none;
}

span[class*=form-control]{
  border: none;
  box-sizing: border-box;
  width: 100%;
  font-size: 1rem;
}


.collection-data {
  padding: 0%;
}

.fa {
  margin: 0 0.5% 0;
}

[class*="form-control"] {
  border-radius: 0%;
}


.main-sub-menu {
  border : none;
}

.doc-name {
  width: 100%;
}

.no-col {

  width: 5vw;

}

.sub_menu {
  width: 10%;
}

.ohpopup {
  background-color: #ffffff;
  width: 30vw;
  height: 30vh;
  display: none;
  z-index: 99;
  position: absolute;
}


.abstract-blue-bg {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.close-btn {
  width: auto;
  height: 4vh;
  cursor: pointer;
}

.close-btn:hover {
  width: auto;
  height: 5vh;
  cursor: pointer;
}

/* [class*="col"] {

} */

.ovhide {
  overflow-x: hidden;
}

[class*="trans"] > div {
  color: #ffffff;
  font-weight:400;
}

.trans {
  background-color: rgba(255, 255, 255, 0.2);
}

.bodycenter {
  transform: translate(-50%,-50%);
  left: 50%;
  top : 50%;
}

.coverup {
  width: 100vw;
  height: 100vh;
}body {
    overflow: hidden;
}

.blank {
    background-color: white;
}

.main-area {
    width: 95vw;
    height: 84vh;
}

.table-body{
    position: relative;
    top: 0;
    left: 0;
    height: 95vw;
    height: 80vh;
    /* overflow-x: scroll; */
    overflow-y: scroll;
}

span {
    letter-spacing: 0.2px;
}

.styling-menu {
    padding: 0.3%;
}

#user_area, #text-color-palette,#fill-background-palette, #kesalahan_komplain_area {
    padding: 2vw;
    position: absolute;
    background-color: white;
    display: none;
    width: 24vw;
}

#user_area {
    z-index: 88;
    width: 15vw;
    padding: 1vw;
}

#nav_pages {
    border: none;
    z-index: 80;
    height: 4vh;
    width: 93vw;
    bottom: 1%;
    left: 50%;
    transform: translateX(-50%);
}

.emp_bubble {
    transform: translateY(-20%);
}

.nav_marketplace {
    background-color: rgb(70, 181, 87);
    color: white;
}

.dot {
    height: 2vw;
    width: 2vw;
    padding: 2%;
    margin : 1px;
    border-radius: 50%;
    border: 1px solid black;
    display: inline-block;
}



#_cont_left_menu {
    position: absolute;
    border: none;
    width: 3vw;
    height: 95vh;
}

#_cont_right_menu {
    position: absolute;
    border: none;
    transform: translate(97vw,0);
    width: 3vw;
    height: 95vh;
}

.bubble {
    padding: 2%;
    margin: 10%;
}

.left_menu {
    border: none;
    border-radius: 50%;
    background-color: black;
    width: 2.5vw;
    height: 2.5vw;
    margin: 0.5vw;
    margin-bottom: 4vh;
}

#magic-point {
    z-index: 2;
    position: absolute;
    width: 10px;
    height: 10px;
    display: none;
}

.form-control:disabled {
    background: #ffffff;
  }

.admin {
    color: white;
    background-color: rgb(26, 123, 201);
}

.admin-inv {
    background-color: white;
    color: rgb(26, 123, 201);
}

.y-a {
    background-color: rgb(203, 231, 255);
}

.y-b {
    background-color: rgb(167, 210, 248);
}

.packing {
    color: white;
    background-color: rgb(204, 36, 36);
}

.cgo {  
    color: white;
    background-color: rgb(70, 181, 87);
}

.op-mesin {
    color: white;
    background-color: rgb(236, 95, 48);
    
}

.z-a {
    object-fit: cover;
    background-color: rgb(236, 95, 48);
    width: 100%;
}

.op-cutting {
    color: white;
    background-color : rgb(223, 67, 189);
}

.se {
    color: white;
    background-color : rgb(0, 0, 0);
}

/* parent.childNodes[1].style.color = "rgb(155, 102, 102)";  */

/* .main-table {
    overflow-y: scroll;
} */

.guide {
    color: rgb(128, 128, 128);
}



[class*="col"] {
    border: 1px solid rgb(239, 239, 239);
}
div[class*="menu-ohp"] {
    padding: none;
}

span[class*=form-control]{
    border: none;
    box-sizing: border-box;
    width: 100%;
    font-size: 1rem;
}


.collection-data {
    padding: 0%;
}

.fa {
    margin: 0 0.5% 0;
}

[class*="form-control"] {
    border-radius: 0%;
}


.main-sub-menu {
    border : none;
}

.doc-name {
    width: 100%;
}

.no-col {

    width: 5vw;

}

.sub_menu {
    width: 10%;
}

.ohpopup {
    background-color: #ffffff;
    width: 30vw;
    height: 30vh;
    display: none;
    z-index: 99;
    position: absolute;
}


.abstract-blue-bg {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.close-btn {
    width: auto;
    height: 4vh;
    cursor: pointer;
}

.close-btn:hover {
    width: auto;
    height: 5vh;
    cursor: pointer;
}

/* [class*="col"] {

} */

.ovhide {
    overflow-x: hidden;
}

[class*="trans"] > div {
    color: #ffffff;
    font-weight:400;
}

.trans {
    background-color: rgba(255, 255, 255, 0.2);
}

.bodycenter {
    transform: translate(-50%,-50%);
    left: 50%;
    top : 50%;
}

.coverup {
    width: 100vw;
    height: 100vh;
}

._logo {
  width: 2rem;
  height: 2rem;
}

.row, .col {
  border: none;
}


.useBorder {
  border: 1px solid black;
}

._spinIt {
  animation: spin 1s infinite linear;  
}

@keyframes spin{
  from {
    transform : rotate(0deg)
  }
  to {
    transform : rotate(360deg)
  }
}

.circ {
  object-fit: cover;
  width:10rem;
  height:10rem;
}

.modalContainer {
  width: 100rem;
}

/* .modalContent {
  width: 150rem;
} */

